import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "about" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("h1", null, "Acerca de Rutazz", -1),
    _createElementVNode("p", null, "Rutazz es una plataforma para descubrir y compartir destinos turísticos en Bolivia. Nuestro objetivo es promover el turismo local y ayudar a los viajeros a encontrar lugares únicos y emocionantes para visitar.", -1),
    _createElementVNode("p", null, "Explora nuestra colección de destinos, comparte tus experiencias y descubre la belleza de Bolivia con Rutazz.", -1)
  ])))
}