<template>
  <div class="sidebar">
    <h3>Filtros</h3>
    <div class="filter">
      <label for="cityFilter">Filtrar por Ciudad:</label>
      <select id="cityFilter" v-model="selectedCity" @change="changeCity">
        <option value="la-paz">La Paz</option>
        <option value="santa-cruz-de-la-sierra">Santa Cruz</option>
        <option value="cochabamba">Cochabamba</option>
        <option value="beni">Beni</option>
        <option value="potosi">Potosí</option>
        <option value="tarija">Tarija</option>
        <option value="sucre">Sucre</option>
        <option value="oruro">Oruro</option>
        <option value="pando">Pando</option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'BaseSidebar',
  setup() {
    const store = useStore()
    const selectedCity = ref(store.getters.getCurrentCity)

    onMounted(() => {
      // Ensure the initial city is set and destinations are fetched
      store.dispatch('setCurrentCity', selectedCity.value)
    })

    const changeCity = () => {
      store.dispatch('setCurrentCity', selectedCity.value)
    }

    return {
      selectedCity,
      changeCity
    }
  }
})
</script>

<style scoped>
.sidebar {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.filter {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #34495e;
}

select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

select:focus {
  outline: none;
  border-color: #3498db;
}
</style>

