import { createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Filtros", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("label", { for: "cityFilter" }, "Filtrar por Ciudad:", -1)),
      _withDirectives(_createElementVNode("select", {
        id: "cityFilter",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCity) = $event)),
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeCity && _ctx.changeCity(...args)))
      }, _cache[2] || (_cache[2] = [
        _createStaticVNode("<option value=\"la-paz\" data-v-2cd907aa>La Paz</option><option value=\"santa-cruz-de-la-sierra\" data-v-2cd907aa>Santa Cruz</option><option value=\"cochabamba\" data-v-2cd907aa>Cochabamba</option><option value=\"beni\" data-v-2cd907aa>Beni</option><option value=\"potosi\" data-v-2cd907aa>Potosí</option><option value=\"tarija\" data-v-2cd907aa>Tarija</option><option value=\"sucre\" data-v-2cd907aa>Sucre</option><option value=\"oruro\" data-v-2cd907aa>Oruro</option><option value=\"pando\" data-v-2cd907aa>Pando</option>", 9)
      ]), 544), [
        [_vModelSelect, _ctx.selectedCity]
      ])
    ])
  ]))
}