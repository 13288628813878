import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "photo-detail"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "photo-info" }
const _hoisted_4 = { class: "upload-date" }
const _hoisted_5 = { class: "description" }
const _hoisted_6 = {
  key: 1,
  class: "no-place-info"
}
const _hoisted_7 = {
  key: 1,
  class: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.photo)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.photo.image,
          alt: _ctx.photo.caption,
          class: "full-image"
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.photo.caption), 1),
          _createElementVNode("p", _hoisted_4, "Uploaded on " + _toDisplayString(_ctx.formatDate(_ctx.photo.upload_date)), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.photo.description), 1)
        ]),
        (_ctx.photo.place && _ctx.photo.place.location)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: { 
        name: 'destination-detail-by-slug', 
        params: { 
          region: _ctx.photo.place.location.region_slug,
          country: _ctx.photo.place.location.country_slug,
          city: _ctx.photo.place.location.city_slug,
          slug: _ctx.photo.place.slug
        } 
      },
              class: "back-link"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Back to " + _toDisplayString(_ctx.photo.place.name), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createElementBlock("p", _hoisted_6, "Place information not available"))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, "Loading..."))
}