import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "destination-detail"
}
const _hoisted_2 = { class: "location" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { class: "photo-gallery" }
const _hoisted_6 = {
  key: 0,
  class: "photo-grid"
}
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = { class: "photo-caption" }
const _hoisted_9 = { class: "photo-date" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 1,
  class: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.destination)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.destination.name), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.destination.location.city) + ", " + _toDisplayString(_ctx.destination.location.region) + ", " + _toDisplayString(_ctx.destination.location.country), 1),
          (_ctx.destination.location.town)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.destination.location.town), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", null, _toDisplayString(_ctx.destination.description), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Photos", -1)),
          (_ctx.destination.photos.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.destination.photos, (photo) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: photo.id,
                    class: "photo-item"
                  }, [
                    _createVNode(_component_router_link, {
                      to: { name: 'photo-detail', params: { id: photo.id } }
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: photo.image,
                          alt: photo.caption
                        }, null, 8, _hoisted_7),
                        _createElementVNode("p", _hoisted_8, _toDisplayString(photo.caption), 1),
                        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.formatDate(photo.upload_date)), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_10, "No photos available"))
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_11, "Loading..."))
}