<template>
  <div v-if="photo" class="photo-detail">
    <img :src="photo.image" :alt="photo.caption" class="full-image">
    <div class="photo-info">
      <h2>{{ photo.caption }}</h2>
      <p class="upload-date">Uploaded on {{ formatDate(photo.upload_date) }}</p>
      <p class="description">{{ photo.description }}</p>
    </div>
    <router-link 
      v-if="photo.place && photo.place.location"
      :to="{ 
        name: 'destination-detail-by-slug', 
        params: { 
          region: photo.place.location.region_slug,
          country: photo.place.location.country_slug,
          city: photo.place.location.city_slug,
          slug: photo.place.slug
        } 
      }" 
      class="back-link"
    >
      Back to {{ photo.place.name }}
    </router-link>
    <p v-else class="no-place-info">Place information not available</p>
  </div>
  <div v-else class="loading">Loading...</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { Photo } from '@/types';

const apiUrl = process.env.VUE_APP_API_URL || 'http://localhost:8000';

export default defineComponent({
  name: 'PhotoDetailView',
  setup() {
    const route = useRoute();
    const photo = ref<Photo | null>(null);

    const fetchPhoto = async () => {
      try {
        const response = await axios.get(`${apiUrl}/photos/${route.params.id}/`);
        photo.value = response.data;
      } catch (error) {
        console.error('Error fetching photo:', error);
      }
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    };

    onMounted(() => {
      fetchPhoto();
    });

    return {
      photo,
      formatDate,
    };
  },
});
</script>

<style scoped>
.photo-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.full-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.photo-info {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
}

h2 {
  margin-bottom: 0.5rem;
}

.upload-date {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.description {
  line-height: 1.6;
}

.back-link, .no-place-info {
  display: inline-block;
  margin-top: 1rem;
  color: #3498db;
  text-decoration: none;
}

.back-link:hover {
  text-decoration: underline;
}

.loading {
  text-align: center;
  font-size: 1.25rem;
  color: #3498db;
  margin-top: 2rem;
}
</style>
