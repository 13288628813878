import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import DestinationsView from '../views/DestinationsView.vue'
import DestinationDetailView from '../views/DestinationDetailView.vue'
import PhotoDetailView from '../views/PhotoDetailView.vue'
import AboutView from '../views/AboutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'destinations',
    component: DestinationsView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/destination/:region/:country/:city/:slug',
    name: 'destination-detail-by-slug',  // This name should match what's used in DestinationCard
    component: DestinationDetailView,
    props: true
  },
  {
    path: '/photo/:id',
    name: 'photo-detail',
    component: PhotoDetailView,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
