<template>
  <div class="about">
    <h1>Acerca de Rutazz</h1>
    <p>Rutazz es una plataforma para descubrir y compartir destinos turísticos en Bolivia. Nuestro objetivo es promover el turismo local y ayudar a los viajeros a encontrar lugares únicos y emocionantes para visitar.</p>
    <p>Explora nuestra colección de destinos, comparte tus experiencias y descubre la belleza de Bolivia con Rutazz.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AboutView'
})
</script>

<style scoped>
.about {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}
</style>
