import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("nav", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Destinos")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, { to: "/about" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Acerca")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view)
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("footer", null, [
      _createElementVNode("p", null, "© 2024 Rutazz. All rights reserved.")
    ], -1))
  ]))
}