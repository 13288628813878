<template>
  <div class="card">
    <router-link :to="{
      name: 'destination-detail-by-slug',
      params: {
        region: destination.location.region_slug,
        country: destination.location.country_slug,
        city: destination.location.city_slug,
        slug: destination.slug
      }
    }">
      <div class="card-image">
        <img :src="thumbnailUrl" :alt="destination.name" class="thumbnail">
      </div>
      <div class="card-content">
        <h2>{{ destination.name }}</h2>
        <p class="description">{{ truncatedDescription }}</p>
        <p class="location">{{ destination.location.city }}, {{ destination.location.region }}, {{ destination.location.country }}</p>
        <p v-if="coverPhoto" class="photo-info">
          <span class="upload-date">{{ formatDate(coverPhoto.upload_date) }}</span>
          <span class="photo-description">{{ truncatePhotoDescription(coverPhoto.description) }}</span>
        </p>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { Destination, Photo } from '@/types';

export default defineComponent({
  name: 'DestinationCard',
  props: {
    destination: {
      type: Object as () => Destination,
      required: true,
    },
  },
  setup(props) {
    const coverPhoto = computed(() => 
      props.destination.photos.find(photo => photo.cover) || props.destination.photos[0]
    );

    const thumbnailUrl = computed(() => {
      if (coverPhoto.value) {
        return coverPhoto.value.image;
      }
      return '/path/to/placeholder-image.jpg';
    });

    const truncatedDescription = computed(() => {
      return props.destination.description.length > 100
        ? props.destination.description.slice(0, 100) + '...'
        : props.destination.description;
    });

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    };

    const truncatePhotoDescription = (description: string) => {
      return description.length > 50 ? description.slice(0, 50) + '...' : description;
    };

    return {
      coverPhoto,
      thumbnailUrl,
      truncatedDescription,
      formatDate,
      truncatePhotoDescription,
    };
  },
});
</script>

<style scoped>
.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-image {
  height: 200px;
  overflow: hidden;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card:hover .thumbnail {
  transform: scale(1.05);
}

.card-content {
  padding: 1.5rem;
}

h2 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #2c3e50;
}

.description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.location {
  font-size: 0.8rem;
  color: #3498db;
  font-weight: 600;
}

a {
  text-decoration: none;
  color: inherit;
}

.photo-info {
  font-size: 0.8rem;
  color: #666;
  margin-top: 0.5rem;
}

.upload-date {
  font-weight: bold;
  margin-right: 0.5rem;
}

.photo-description {
  font-style: italic;
}
</style>

