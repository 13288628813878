<template>
  <div v-if="destination" class="destination-detail">
    <h1>{{ destination.name }}</h1>
    <div class="location">
      <p>{{ destination.location.city }}, {{ destination.location.region }}, {{ destination.location.country }}</p>
      <p v-if="destination.location.town">{{ destination.location.town }}</p>
    </div>
    <div class="description">
      <p>{{ destination.description }}</p>
    </div>
    <div class="photo-gallery">
      <h2>Photos</h2>
      <div v-if="destination.photos.length" class="photo-grid">
        <div v-for="photo in destination.photos" :key="photo.id" class="photo-item">
          <router-link :to="{ name: 'photo-detail', params: { id: photo.id } }">
            <img :src="photo.image" :alt="photo.caption">
            <p class="photo-caption">{{ photo.caption }}</p>
            <p class="photo-date">{{ formatDate(photo.upload_date) }}</p>
          </router-link>
        </div>
      </div>
      <p v-else>No photos available</p>
    </div>
  </div>
  <div v-else class="loading">Loading...</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { Destination } from '@/types';
import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URL || 'http://localhost:8000';

export default defineComponent({
  name: 'DestinationDetailView',
  props: {
    region: String,
    country: String,
    city: String,
    slug: String,
  },
  setup(props) {
    const route = useRoute();
    const destination = ref<Destination | null>(null);

    const fetchDestination = async () => {
      try {
        const response = await axios.get(`${apiUrl}/places/`, {
          params: {
            region: props.region,
            country: props.country,
            city: props.city,
            slug: props.slug,
          },
        });
        destination.value = response.data[0]; // Assuming the API returns an array with one item
      } catch (error) {
        console.error('Error fetching destination:', error);
      }
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    };

    onMounted(() => {
      fetchDestination();
    });

    return {
      destination,
      formatDate,
    };
  },
});
</script>

<style scoped>
.destination-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.location {
  font-style: italic;
  color: #3498db;
  margin-bottom: 1rem;
}

.description {
  margin-bottom: 2rem;
  line-height: 1.6;
}

.photo-gallery h2 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.photo-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.photo-item img:hover {
  transform: scale(1.05);
}

.loading {
  text-align: center;
  font-size: 1.25rem;
  color: #3498db;
  margin-top: 2rem;
}

.photo-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.photo-modal img {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
  border-radius: 4px;
}

.photo-modal p {
  color: white;
  margin-top: 1rem;
  font-size: 1rem;
  text-align: center;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.photo-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.photo-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.photo-item:hover img {
  transform: scale(1.05);
}

.photo-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.photo-date {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
}
</style>

